/* *,
*::after,
*::before {
  transition: all 0.25s ease;
} */

h1,
h2,
h3,
p {
    margin: 0;
}

h3 {
    font-size: 1.125em;
}

img {
    display: block;
    max-width: 100%;
}



.react-colorful {}

.react-colorful__saturation {
    border-radius: 16px 16px 0 0 !important;
    max-width: 100%;
}

.react-colorful__alpha,
.react-colorful__hue {
    border-radius: 0 0 16px 16px !important;
    height: 32px !important;
}

.side-bar-hover .ql-container {
    height: unset !important;
}

.side-bar-hover.quill:not(.field) .ql-editor {
    max-height: 60px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.brandbookSearch:focus {
    outline: unset;
}

.swiper-slide {
    width: max-content !important;
}

html {
    scroll-behavior: smooth;
    scroll-padding-block-start: 5vmin;
}

body::-webkit-scrollbar {
    width: 0;

}


/* width */
body *::-webkit-scrollbar {
    width: 6px;
    height: 0;
}

/* Track */
body *::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
body *::-webkit-scrollbar-thumb {
    background: rgba(213, 198, 173, 1);
    border-radius: 100vw;
}

/* Handle on hover */
body *::-webkit-scrollbar-thumb:hover {
    background: hsl(37, 32%, 66%);
}